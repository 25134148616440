/**
 * @description 验证小数的最大长度
 * @param {string|number} value 验证数字
 * @param {number} len 小数的最大位数 默认为2
 * @returns {string}
 */
const verifyDecimal = (value, len = 2) =>{
  let str = ''
  const arr = `${value}`.split('.')
  if(arr.length > 1 && arr[1].length > len) {
    str = `数字精确到小数点后${len}位`
  }
  return str
}

/**
 * @description 验证数字
 * @param {string|number} value 验证数字
 * @param {boolean} isInteger 是否验证整数
 * @returns
 */
const verifyNumber = (value, isInteger = false) =>{
  let str = ''
  if (value === '') {
    str = '请输入'
  } else if (isNaN(Number(value))) {
    str = '请输入数字'
  } else if(isInteger && !Number.isInteger(Number(value))) {
    str = '请输入整数'
  }
  return str
}

/**
 * @description 自定义验证数字的大小
 * @param {string|number|Array} value 验证数据
 * @param {Function} callback 返回函数
 * @param {object} custom 返回函数
 */
export const validateNumber = (value, callback, custom) => {
  const { isInteger = false, len, min, max, equalToMin = false, equalToMax = false } = custom
  const v1 = verifyNumber(value, isInteger)
  v1 && callback(new Error(v1))
  const showText = isInteger ? '整数' : '数字'
  if(isNaN(Number(min)) && isNaN(Number(max))) {
    callback()
  } else if(isNaN(Number(max)) && !isNaN(Number(min)) && (equalToMin ? Number(value) < min : Number(value) <= min)){
    callback(new Error(`请输入大于${equalToMin ? '等于' : ''}${min}的${showText}`))
  } else if(isNaN(Number(min)) && !isNaN(Number(max)) && (equalToMax ? Number(value) > max : Number(value) >= max)){
    callback(new Error(`请输入小于${equalToMax ? '等于' : ''}${max}的${showText}`))
  } else if((equalToMin ? Number(value) < min : Number(value) <= min) || (equalToMax ? Number(value) > max : Number(value) >= max)){
    (!equalToMin && !equalToMax) && callback(new Error(`请输入${min}-${max}以内的${showText}`))
    callback(new Error(`请输入大于${equalToMin ? '等于' : ''}${min}且小于${equalToMax ? '等于' : ''}${max}的${showText}`))
  } else {
    const v2 = verifyDecimal(value, len)
    v2 && callback(new Error(v2))
    callback()
  }
}
